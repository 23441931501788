.fa-file-pdf {
  color: $colorFilePDF !important;
}

.fa-file-excel,
.fa-file-spreadsheet,
.fa-file-csv {
  color: $colorFileSpreadsheet !important;
}

.fa-file-word,
.fa-file-alt {
  color: $colorFileText !important;
}

.fa-file-powerpoint {
  color: $colorFilePresentation !important;
}

.fa-rss-square {
  color: $colorRSS !important;
}

.fa-youtube {
  color: $colorYouTube !important;
}

.fa-vimeo {
  color: $colorVimeo !important;
}

.fa-typo3 {
  color: $colorTYPO3 !important;
}

.fa-php {
  color: $colorPHP !important;
}

.btn {
  .fa-file-pdf,
  .fa-file-excel,
  .fa-file-spreadsheet,
  .fa-file-csv,
  .fa-file-word,
  .fa-file-alt,
  .fa-file-powerpoint,
  .fa-rss-square,
  .fa-youtube,
  .fa-vimeo,
  .fa-typo3,
  .fa-php {
    color: inherit !important;
  }
}

.crossIcon {
  position: relative;

  &::after {
    content: "\f715";
    position: absolute;
    top: -1px;
    left: -1px;
    text-shadow: 2px 2px 4px $black;
  }
}