// Color (allgemein)
$mainColorWhite: #ffffff;
$mainColorBlack: #000000;
$mainColorLightGrey: #ebebeb;

$headerColorGrey: #363636;
$headerColorLightGrey: #f7f7f7;

$borderColorGrey: #dfdfdf;

$linkColor: $dlrgColorBlue;
$linkColorHover: $dlrgColorBlueHover;

$dlrgColorThead: #037ad1;

$colorFilePDF: #A00000;
$colorFileText: #376EA8;
$colorFileSpreadsheet: #2A8259;
$colorFilePresentation: #da4a01;
$colorRSS: #FFA500;
$colorYouTube: #FF0000;
$colorVimeo: #44BBFF;
$colorTYPO3: #ff8700;
$colorPHP: #8A93BE;

// App Colors
$dlrgAppMeineServices: #d7005f;
$dlrgAppAllgemein: #367CCE;
$dlrgAppAusbildung: #BF7700;
$dlrgAppEinsatz: #e30613;
$dlrgAppInternet: #417505;
$dlrgAppStammdaten: #8B572A;
$dlrgAppLinks: #0069b4;
$dlrgAppPruefungen: #d55900;
$dlrgAppTools: #9013FE;
$dlrgAppAKI: #004EE5;
$dlrgAppVerwaltung: #E100B1;
$dlrgGreyTransparent: #333333bb;

// Parsley
$parsleyErrorColor: #B94A48;
$parsleyErrorBg: #F2DEDE;
$parsleyErrorBorder: #EED3D7;
$parsleySuccessColor: #468847;
$parsleySuccessBg: #DFF0D8;
$parsleySuccessBorder: #D6E9C6;
$parsleyErrorList: #E88A89;

// Table
$table-hover-bg: rgba(black, 0.3);