header + div > .row {
  & > main {
    max-height: calc(100vh - 57px);
    min-height: 500px;
    height: calc(100vh - 57px);
  }

  & > main {
    overflow-y: auto;

    @media (min-width: 1200px) {
      &:not(.col-md-12) {
        max-width: calc(100vW - 300px);
      }
    }

    &.col-md-12 > #content:first-child {
      margin-top: 30px;
    }
  }
}

.breadcrumb {
  padding: 0;
  background: none;
}

#content {
  .container,
  .container-fluid {
    &:not([class*="col"]) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  #scrollToTop {
    position: sticky;
    z-index: 98;
    bottom: 10px;
    float: right;
    clear: both;
    margin-top: -2rem; //unnötigen Platz entfernen
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.container,
.container-fluid {
  a:not([href]):not([class]){ //weil BS hier blöd ist
    color: $linkColor;

    &:hover {
      color: $linkColorHover;
      text-decoration: underline;
    }
  }
}

.text-black {
  color: $black;
}

.textWhite {
  color: $mainColorWhite;
}

.bg-thead {
  background: $dlrgColorThead !important;
}

.btn-yellow {
  background-color: $dlrgColorYellow;
  border-color: $dlrgColorYellow;
  color: $black;

  &:hover {
    background-color: $dlrgColorYellowHover;
    border-color: $dlrgColorYellowHover;
    color: $black; //muss definiert werden für den DarkMode
  }
}


.bg-light-primary {
  background: lighten($primary, 25%);
}

.bg-light-secondary {
  background: lighten($secondary, 25%);
}

.bg-light-info {
  background: lighten($info, 25%);
}

.bg-light-warning {
  background: lighten($warning, 25%);
}

.bg-light-danger {
  background: lighten($danger, 25%);
}

.bg-light-dark {
  background: lighten($dark, 25%);
}

.bg-light-light {
  background: lighten($light, 25%);
}

.bg-primary,
.bg-light-primary {
  &.text-primary,
  .text-primary {
    color: $white !important;
  }
}

.bg-secondary,
.bg-light-secondary {
  &.text-secondary,
  .text-secondary {
    color: $black !important;
  }
}

.bg-success,
.bg-light-success {
  &.text-success,
  .text-success {
    color: $white !important;
  }
}

.bg-info,
.bg-light-info {
  &.text-info,
  .text-info {
    color: $black !important;
  }
}

.bg-warning,
.bg-light-warning {
  &.text-warning,
  .text-warning {
    color: $black !important;
  }
}

.bg-danger,
.bg-light-danger {
  &.text-danger,
  .text-danger {
    color: $white !important;
  }
}

.bg-dark,
.bg-light-dark {
  &.text-dark,
  .text-dark {
    color: $mainColorWhite !important;
  }
}

.bg-light,
.bg-light-light {
  &.text-light,
  .text-light {
    color: $mainColorBlack !important;
  }
}

.btn {
  &.fa-stack {
    display: flex;
    min-height: 31px;

    .fa-stack-2x {
      font-size: 1.3em;
    }
  }
}

.btn-group {
  > .btn:not(:first-child),
  > .btn-group:not(:first-child) {
    margin-left: 0;
  }

  .btn {
    &:not([class*="outline"]) {
      &:not(:first-child):not(:last-child) {
        border-right: none;
      }

      &:first-child {
        border-right: none;
      }
    }

    &:not(:first-child) {
      border-left: none;
    }
  }

  *:last-child:not(:first-child) {
    &.form-control,
    .form-control,
    .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  *:first-child:not(:last-child) {
    &.form-control,
    .form-control,
    .btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.dropdown-menu {
  .btn {
    .fa,
    .fas,
    .far,
    .fal,
    .fab {
      width: 18px;
      text-align: center;
    }
  }
}

.progress {
  height: 25px;
  background-color: $secondary;

  .progress-bar {
    overflow: visible;

    &.bg-warning,
    &.bg-light {
      color: $black;
    }
  }
}

.empty-d-none:empty {
  display: none !important;
}

ul {
  padding-left: 1.5rem;
  list-style: initial;

  &.fa-ul {
    margin-left: 1.5rem;
  }
}

#myCarousel {
  .carousel-inner {
    .carousel-item {
      img {
        object-fit: cover;
      }
    }
  }
}

.overflow-unset {
  overflow: unset;
}

.hyphens-auto {
  hyphens: auto !important;
}

// BS-Select
.bootstrap-select .no-results {
  background: none;
  white-space: normal;
}

.termin-legende {
  display: flex;
  flex-wrap: wrap;

  span {
    flex-grow: 1;
    box-sizing: content-box;
    margin: 5px;
    padding: 0 4px;
    position: relative;
    display: block;
    border-radius: 3px;
    border: 1px solid $gray-800;
    width: 200px;
  }
}


// 16/9-Format bei Bildern
.picture-16-9 {
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

#loadingButton {
  position: fixed;
  bottom: 1rem;
  z-index: 9999;
  left: calc(50% - 30px);
}

.mx-minus-3 {
  margin-left: -15px !important;
  margin-right: -15px !important;
}


// Full Calender Popover soll Zeilenumbrüche anzeigen
.popover {
  white-space: pre-line !important;
}


// Transparenz/Opacity: BS4 hat dies noch nicht, daher manuell implementieren
.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 100 !important;
}