.fc.fc-theme-bootstrap {
  .fc-header-toolbar {
    flex-wrap: wrap;
  }

  .fc-scrollgrid {
    .fc-daygrid-day {
      padding: 0 !important;

      .fc-daygrid-day-number,
      .fc-daygrid-week-number {
        font-size: 0.9em;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .fixCalenderToolbar {
    &.fc.fc-theme-bootstrap {
      .fc-header-toolbar {
        background: $light;
        border-bottom: 1px solid $gray-400;
        margin: -15px;
        margin-top: -2rem;
        margin-bottom: 1rem;
        padding: 0.5rem 1rem;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .fc-daygrid-day {
    .fc-event-time {
      display: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  .fc-daygrid-day {
    .fc-daygrid-week-number {
      display: none;
    }
  }
}