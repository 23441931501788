div.log {
  background-color: $mainColorLightGrey;
  border: 1px dotted $dlrgColorGreen;
  font-family: monospace;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;

  .logerror {
    color: #ff0000;
    display: inline;
  }

  .logwarning {
    color: $dlrgColorYellow;
    display: inline;
  }

  .lognotice {
    color: $dlrgColorGreen;
    display: inline;
  }

  .logtime {
    color: $mainColorBlack;
    display: inline;
  }

  .lognew {
    background-color: $dlrgColorGreen;
  }

  .logline:hover {
    background-color: $dlrgColorYellowHover;
  }
}