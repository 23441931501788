// Fontawesome
$fa-font-path: '../fontawesome/';
$fontawesome: 'Font Awesome 5 Pro';

//vendor
@import '../../node_modules/bootstrap/scss/bootstrap';

@import '../../node_modules/bootstrap-table/src/bootstrap-table';
@import '../../node_modules/bootstrap-select/sass/bootstrap-select';
@import '../../node_modules/tempusdominus-bootstrap-4/src/sass/tempusdominus-bootstrap-4';
@import '../../node_modules/parsleyjs/src/parsley';

@import '../../node_modules/fullcalendar/main';

//Asset-App
@import "../../node_modules/ekko-lightbox/dist/ekko-lightbox";
@import "../static/jquery-tokeninput@1.7.0/token-input";
@import "../static/jquery-tokeninput@1.7.0/token-input-facebook";

$bsBreakpoints: sm, md, lg, xl;

// partials
@import 'partials/alert';
@import 'partials/apps';
@import 'partials/asset';
@import 'partials/asset_dropzone';
@import 'partials/base';
@import 'partials/calendar';
@import 'partials/cards';
@import 'partials/forms';
@import 'partials/icons';
@import 'partials/dev-env';
@import 'partials/modals';
@import 'partials/spaltenelemente';
@import 'partials/table';
@import 'partials/toolbar';
@import 'partials/tooltip';
@import 'partials/veranstaltungsanmeldung';

@import 'partials/header';
@import 'partials/sidebar';
@import 'partials/main';
@import 'partials/footer';

@import 'partials/shame';
@import 'partials/log';

// als letztes, dass Sticky-Positionswerte zählen
@import 'partials/sticky';