#contentseite,
#startseite {
  /*------------------------------------------*/
  /*              btnBarSticky                */
  /*------------------------------------------*/
  .btnBarSticky.btn-toolbar {
    padding: 0.5rem 1rem;
    margin: -15px;
    margin-top: -30px;
    margin-bottom: 15px;
    border-bottom: 1px solid $gray-400;
    z-index: 98;
    position: sticky;
    top: -15px;
  }


  /*------------------------------------------*/
  /*              fixCardHeader               */
  /*------------------------------------------*/
  // auf schmalen Bildschirmen nicht fixieren
  .fixCardHeader {
    background: $headerColorLightGrey;
    padding-top: 18px; // dass genauso hoch wie fixToolbar (sonst schwierig mit weiteren sticky-Elemente)
  }

  @include media-breakpoint-up(sm) {
    // 1. fixierte Ebene
    .fixCardHeader {
      background: $headerColorLightGrey;
      padding-top: 14px; // dass genauso hoch wie fixToolbar (sonst schwierig mit weiteren sticky-Elemente)
      position: sticky;
      z-index: 91;
      top: -15px; // standardmäßig unter Menüleiste fixiert
    }

    // 2. fixierte Ebene
    .btnBarSticky {
      & ~ form > .card,
      & ~ .card {
        &,
        & > form {
          > .fixCardHeader {
            top: 41px;
          }
        }
      }
    }

    // in Modals
    .modal {
      .fixCardHeader {
        top: -1rem; // padding von modal-body ausgleichen
        border-top-left-radius: 0; // kein Radius, da fixiert
        border-top-right-radius: 0; // kein Radius, da fixiert
      }
    }
  }


  /*------------------------------------------*/
  /*              fixCardToolbar              */
  /*------------------------------------------*/
  .card-body {
    .fixCardToolbar.btn-toolbar {
      background: linear-gradient(to bottom, $card-bg, $headerColorLightGrey);
      border-bottom: 1px solid $card-border-color;
      margin: -$card-spacer-x;
      margin-bottom: 15px;
      padding: 10px $card-spacer-x;

      .dropdown-menu {
        transform: unset !important; // important, dass inline CSS überschrieben wird
        top: 35px !important; // important, dass inline CSS überschrieben wird
        max-height: calc(100vH - 170px);
        overflow-x: auto;
      }
    }
  }

  // auf schmalen Bildschirmen nicht fixieren
  @include media-breakpoint-up(sm) {
    // 2. fixierte Ebene (muss mind. in Card stehen)
    .card-body {
      .fixCardToolbar.btn-toolbar {
        position: sticky;
        z-index: 90;
        top: 41px;
      }
    }

    // 3. fixierte Ebene
    .btnBarSticky {
      & ~ .card {
        // Selektor darf nur 3 Ebenen tief gehen.
        &,
        & > *,
        & > * > * {
          & > .card-body {
            // Selektor darf nur 2 Ebenen tief gehen.
            &,
            & > * {
              & > .fixCardToolbar.btn-toolbar {
                top: 95px;
              }
            }
          }
        }
      }
    }
  }


  /*------------------------------------------*/
  /*            fixTableHeader                */
  /*------------------------------------------*/
  //table in table definitiv (daher !important) nicht fixieren
  table {
    table {
      thead {
        position: unset !important;
        top: unset !important;
        z-index: unset !important;
      }
    }
  }



  // 1. fixierte Ebene
  @include media-breakpoint-up(sm) {
    .fixTableHeader.active {
      .fixed-table-body {
        overflow: unset !important;
      }

      .fixed-table-toolbar {
        position: relative;
        z-index: 98;
        padding: 0.5rem 1rem;
        margin: -15px;
        margin-top: -30px;
        margin-bottom: 15px;
        border-bottom: 1px solid $gray-400;
        background: $light;

        // unter Brekpoint md Table-Toolbar zweizeilig, dass genug Platz für Buttons
        @include media-breakpoint-down(md) {
          min-height: 95px;
        }
      }

      .fixed-table-toolbar {
        position: sticky;
        top: -15px; // standardmäßig unter Menüleiste fixiert
        z-index: 98
      }

      .fixed-table-toolbar + .fixed-table-container thead {
        position: sticky;
        top: -15px; // standardmäßig unter Menüleiste fixiert
        z-index: 88;
      }
    }
  }

  // wenn TableToolbar auf false gesetzt ist, diese ausblenden und Kopfzeile unter Menüleiste fixieren
  .fixTableHeader.noTableToolbar.active {
    .fixed-table-toolbar {
      display: none;
    }

    @include media-breakpoint-up(sm) {
      .fixed-table-toolbar + .fixed-table-container thead {
        top: -15px; // standardmäßig unter Menüleiste fixiert
      }
    }
  }

  // 2. fixierte Ebene
  @include media-breakpoint-up(sm) {
    .fixTableHeader.active {
      .fixed-table-toolbar + .fixed-table-container thead {
        top: 40px; // unter 1. fixierter Ebene fixieren

        // unter Brekpoint md Table-Toolbar zweizeilig, daher tiefer fixieren
        @include media-breakpoint-down(md) {
          top: 79px;
        }
      }
    }
  }

  .btnBarSticky,
  .fixCardHeader {
    & ~ * .fixTableHeader.active,
    & ~ .fixTableHeader.active {
      @include media-breakpoint-up(sm) {
        .fixed-table-toolbar + .fixed-table-container thead {
          top: 40px; // unter 1. fixierter Ebene fixieren
        }
      }

      // TableToolbar darf unter btnBarSticky nicht mehr fixiert werden
      .fixed-table-toolbar {
        position: unset;
        top: unset;
        // wenn nicht sticky, dann kein besonderes Styling
        padding: unset;
        margin: unset;
        margin-bottom: 0.5rem;
        background: unset;
        border: unset;
      }
    }
  }

  // in fixCardHeader kann Table-Toolbar fixiert werden
  .fixCardHeader {
    & ~ * .fixTableHeader.active:not(.noTableToolbar),
    & ~ .fixTableHeader.active:not(.noTableToolbar) {
      .fixed-table-toolbar {
        background: linear-gradient(to bottom, $card-bg, $headerColorLightGrey);
        border-bottom: 1px solid $card-border-color;
        margin: -$card-spacer-x;
        margin-bottom: 15px;
        padding: 10px $card-spacer-x;

        .bs-bars,
        .columns,
        .search {
          margin-top: unset;
          margin-bottom: unset;
        }
      }

      @include media-breakpoint-up(sm) {
        .fixed-table-toolbar + .fixed-table-container thead {
          top: 100px; // unter 1. fixierter Ebene fixieren

          // unter Brekpoint md Table-Toolbar zweizeilig, daher tiefer fixieren
          @include media-breakpoint-down(md) {
            top: 135px;
          }
        }

        .fixed-table-toolbar {
          position: sticky;
          z-index: 89;
          top: 41px;
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    // 3/4. fixierte Ebene
    .btnBarSticky {
      ~ .card {
        .fixCardHeader {
          & ~ * .fixTableHeader.active:not(.noTableToolbar),
          & ~ .fixTableHeader.active:not(.noTableToolbar) {
            .fixed-table-toolbar + .fixed-table-container thead {
              top: 153px; // unter 3. fixierter Ebene fixieren

              // unter Brekpoint md Table-Toolbar zweizeilig, daher tiefer fixieren
              @include media-breakpoint-down(md) {
                top: 188px;
              }
            }

            .fixed-table-toolbar {
              top: 94px; // unter 2. fixierter Ebene fixieren
            }
          }

          & ~ * .fixTableHeader.active.noTableToolbar,
          & ~ .fixTableHeader.active.noTableToolbar {
            .fixed-table-toolbar + .fixed-table-container thead {
              top: 94px; // unter 2. fixierter Ebene fixieren
            }
          }
        }
      }
    }
  }


  /*------------------------------------------*/
  /*            fixTable in Modal             */
  /*------------------------------------------*/
  .modal-dialog {
    .fixTableHeader.active:not(.noTableToolbar) {
      .fixed-table-toolbar {
        background: linear-gradient(to bottom, $card-bg, $headerColorLightGrey);
        border-bottom: 1px solid $card-border-color;
        margin: -1rem;
        margin-bottom: 15px;
        padding: 10px 1rem;
        top: -16px;

        .bs-bars,
        .columns,
        .search {
          margin-top: unset;
          margin-bottom: unset;
        }
      }
    }

    .dlrg-table {
      thead {
        position: sticky;
        top: -1rem;
        z-index: 1;
      }
    }
  }

  /*------------------------------------------*/
  /*           Full Calendar (Termine)        */
  /*------------------------------------------*/
  @include media-breakpoint-up(lg) {
    .fixCalenderToolbar {
      &.fc.fc-theme-bootstrap {
        .fc-header-toolbar {
          position: sticky;
          top: -1rem;
          z-index: 99;
        }
      }
    }
  }
}