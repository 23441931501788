.modal {
  .modal-dialog,
  .modal-content {
    &:not(.modal-dialog-scrollable) {
      max-height: calc(100vH - 1rem);
    }
  }

  .modal-dialog {
    display: flex;
  }

  .modal-dialog {
    overflow: hidden;
  }

  .modal-body {
    overflow-y: auto;
  }

  .modal-header {
    background: $headerColorLightGrey;
  }

  .card {
    &:not(.cardAcc) {
      margin-top: -1rem;
      margin-left: -1rem;
      margin-right: -1rem;
      border: none;
    }
  }

  // Bilder sollen nicht breiter werden dürfen als das Modal
  img,
  canvas {
    max-width: 100%;
  }

  .selectpicker ~ .dropdown-menu {
    max-width: calc(100vW - 60px);

    .inner{
      max-height: calc(250px - 98px) !important;
    }
  }

  @include media-breakpoint-up(sm) {
    .modal-dialog,
    .modal-content {
      &:not(.modal-dialog-scrollable) {
        max-height: calc(100vH - 3.5rem);
      }
    }

    .modal-sm {
      .col-sm-9 {
        .selectpicker ~ .dropdown-menu {
          max-width: calc(#{$modal-sm} * 9 / 12 - 60px);
        }
      }
    }

    .modal-md {
      .col-sm-9 {
        .selectpicker ~ .dropdown-menu {
          max-width: calc(#{$modal-md} * 9 / 12 - 60px);
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .modal-lg,
    .modal-xl {
      .col-sm-9 {
        .selectpicker ~ .dropdown-menu {
          max-width: calc(#{$modal-lg} * 9 / 12 - 60px);
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .modal-xl {
      .col-sm-9 {
        .selectpicker ~ .dropdown-menu {
          max-width: calc(#{$modal-xl} * 9 / 12);
        }
      }
    }
  }
}