.dlrg-bs-table {
  .row-kopfzeile {
    color: $white;
    background: $dlrgColorThead;

    > div {
      padding: 1.5625rem .75rem;
      vertical-align: top;
      border: 0;
    }
  }

  .row-striped {
    padding: 0.75rem 0;
    vertical-align: top;
    border: 0;

    > div[class*="col"] {
      margin-bottom: 0.75rem;
    }

    &:nth-of-type(odd) {
      background-color: $gray-300;
    }
  }

  .row-hover:hover {
    background-color: $gray-400;
  }

  .show-full {
    display: none;
  }

  .screen-small {
    &::after {
      content: "\A";
      white-space: pre;
    }
  }

  @include media-breakpoint-up(sm) {
    .screen-sm {
      &.show-full {
        display: unset;
      }

      &.screen-small {
        display: none;
      }

      &.row-striped {
        > div {
          margin-bottom: 0;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .screen-md {
      &.show-full {
        display: unset;
      }

      &.screen-small {
        display: none;
      }

      &.row-striped {
        > div {
          margin-bottom: 0;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .screen-lg {
      &.show-full {
        display: unset;
      }

      &.screen-small {
        display: none;
      }

      &.row-striped {
        > div {
          margin-bottom: 0;
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .screen-xl {
      &.show-full {
        display: unset;
      }

      &.screen-small {
        display: none;
      }

      &.row-striped {
        > div {
          margin-bottom: 0;
        }
      }
    }
  }
}