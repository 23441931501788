#contentseite,
#startseite {
  .breadcrumb {
    margin: -15px;
    margin-top: -16px;
    margin-bottom: 15px;
    padding: 1rem;
    position: relative;
    z-index: 96;
    border-bottom: 1px solid $gray-400;
    border-radius: 0;
  }

  .dlrg-alert-toolbar {
    position: relative;
    z-index: 97;
    padding: 1rem;
    margin: -15px;
    margin-top: -30px;
    margin-bottom: 15px;
    border-bottom: 1px solid $gray-400;
    background: $light;

    &:empty {
      display: none;
    }

    .alert {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .card {
    .dlrg-alert-toolbar {
      z-index: 90;
    }
  }

  // alert-Toolbars, die nach der btn-toolbar kommen (gleiche oder tiefere Ebene), sollen keinen Hintergrund etc. mehr haben
  .btn-toolbar ~ .dlrg-alert-toolbar,
  .btn-toolbar ~ * .dlrg-alert-toolbar,
  .card .dlrg-alert-toolbar {
    padding: unset;
    margin: unset;
    border-bottom: none;
    background: none;

    // Standrad-Abstand zu anderen Elementen zuweisen
    .alert {
      margin-bottom: 1rem;
    }
  }

  .fixed-table-toolbar {
    margin-bottom: 8px;

    .bs-bars,
    .columns,
    .search {
      margin-top: unset;
      margin-bottom: unset;
    }
  }

  .btn-toolbar {
    margin-top: 10px;
    margin-bottom: 10px;
    flex-wrap: nowrap;

    @include media-breakpoint-down(sm) {
      & > .toolbarBlock {
        &:not(:last-child) {
          flex-basis: 0;
          flex-grow: 1;
          max-width: 100%;
        }

        &:last-child {
          flex: 0 0 auto;
          width: auto;
          max-width: 100%;
        }
      }
    }

    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
    }

    & .d-flex {
      flex-wrap: wrap;
    }

    > .btn,
    > .btn-group,
    > .bootstrap-select,
    > .form-control,
    .toolbarBlock {
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }

    .toolbarBlock {
      width: unset;

      & + .toolbarBlock {
        margin-top: 0;

        &:last-of-type {
          margin-left: auto;

          @include media-breakpoint-down(sm) {
            > .btn,
            > .btn-group {
              margin-right: 0;
              margin-left: 5px;
              float: right;
            }
          }

          @include media-breakpoint-down(md) {
            &.searchBlock {
              max-width: unset;
            }
          }
        }
      }
    }

    .btn-group {
      .dropdown-menu {
        padding-top: 0;
        padding-bottom: 0;

        button {
          &:first-of-type {
            margin-top: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }

          &:last-of-type {
            margin-bottom: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }

          &:not(:first-of-type):not(:last-of-type) {
            border-radius: 0;
          }
        }
      }
    }

    // Dropdown-Button in Toolbar extra behandeln
    .dropdown.btn {
      padding-left: 0;
      padding-right: 0;

      > span {
        padding: 8px;
      }

      .dropdown-menu {
        background: none;
        border: none;
        padding: 0;
        top: -4px !important;
        left: unset !important;
        margin-left: -98px;
        width: max-content;
        max-width: calc(100vW - 2rem);

        .btn {
          display: block;
          width: 100%;
          text-align: inherit;
        }
      }
    }

    .dropdown-menu {
      ul.dropdown-menu {
        min-width: unset !important;
      }
    }
  }

  .btn-toolbar,
  .bs-bars,
  .fixed-table-toolbar,
  .pagination {
    .btn,
    .form-control,
    .input-group,
    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text,
    .page-item {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }

    .input-group {
      .btn,
      .form-control,
      .input-group-text {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    *[class*="col"]:not(.col-form-label) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  // kein margin eine toolbar, wenn diese bereits in einer toolbar ist
  // sonst ist das margin doppelt
  .bs-bars,
  .btn-toolbar {
    .bs-bars,
    .btn-toolbar {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .fixed-table-pagination {
    .pagination {
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: fit-content;
  }
}

// Sonderfall: Fixierte Table-Toolbar
#tableContainer {
  .dlrg-bs-table-fixed-toolbar-md,
  .dlrg-bs-table-fixed-toolbar-lg,
  .dlrg-bs-table-fixed-toolbar-xl {
    .columns,
    .bs-bars,
    .search {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}