.row.dlrg-spaltenelement:not(.form-group) {
  > [class*="col"] {
    display: flex;
    flex-direction: column;

    .dlrg-flexbox {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
}

.row.dlrg-spaltenelement {
  padding-left: 7.5px;
  padding-right: 7.5px;

  > [class*="col"] {
    padding: 7.5px;
  }

  &.form-group > [class*="col"] {
    padding-top: 0;
    padding-bottom: 0;

    &[class*="label"],
    >.form-check:first-of-type {
      padding-top: 9px;
    }
  }
}