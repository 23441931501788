body.dev {
  header {
    background: $dlrgColorBlue;
  }
}

// php-Fehlermeldungen
.xdebug-error,
.xe-warning {
  &:last-of-type {
    margin-bottom: 3rem;
  }
}

#content > br:last-of-type {
  margin-bottom: 3rem;
}