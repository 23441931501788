@function grd12($flx-width, $flx-margin: 2px) {
  @return calc(#{(calc($flx-width / 12) * 100%)} - #{$flx-margin});
}

@function grd9($flx-width, $flx-margin: 2px) {
  @return calc(#{(calc($flx-width / 9) * 100%)} - #{$flx-margin});
}

$contentContainerHeight: calc(100vH - 236px);
$uploadRibbonHeight: 200px;
$videoRibbonHeight: 76px;
$folderRibbonHeight: 76px;
$searchRibbonHeight: 130px;
$searchRibbonDokumenteHeight: 76px;

#contentseite,
#startseite {
  @include media-breakpoint-up(lg) {
    #treeContainer,
    #mainContentContainer {
      height: $contentContainerHeight;
      overflow-y: auto;

      &.uploadRibbonOpen {
        height: calc(#{$contentContainerHeight} - #{$uploadRibbonHeight});
      }

      &.addMovieRibbonOpen {
        height: calc(#{$contentContainerHeight} - #{$videoRibbonHeight});
      }

      &.addFolderRibbonOpen {
        height: calc(#{$contentContainerHeight} - #{$folderRibbonHeight});
      }

      &.searchRibbonOpen {
        height: calc(#{$contentContainerHeight} - #{$searchRibbonHeight});
      }

      &.searchRibbonDokumenteOpen {
        height: calc(#{$contentContainerHeight} - #{$searchRibbonDokumenteHeight});
      }
    }

    .pickerMainTreeContainer,
    .pickerMainContentContainer,
    .pickerMainSearchContainer {
      max-height: calc(100vH - 350px);
      overflow-y: auto;
    }

    .AssetPickerModal {
      .card {
        .tab-content {
          &,
          .pickerMainTreeContainer,
          .pickerMainContentContainer {
            min-height: 300px;
          }
        }
      }
    }
  }

  #treeContainer,
  .pickerMainTreeContainer {
    white-space: nowrap;

    @include media-breakpoint-up(lg) {
      border-right: 1px solid $gray-400;
    }

    @include media-breakpoint-down(md) {
      border-bottom: 1px solid $gray-400;
    }

    >ul.fa-ul {
      .assetFolderLabel {
        padding-right: 0.5rem;
      }
    }

    ul.fa-ul {
      >li {
        &:hover {
          .assetFolderIcon,
          .assetFolderLabel {
            transition: opacity 0.2s;
          }

          .assetFolderIcon {
            opacity: 0.6;
          }

          .assetFolderAction,
          .assetFolderLabel {
            opacity: 0.85;
          }
        }

        a:hover {
          text-decoration: none;
        }
      }
    }
  }

  #mainContentContainer {
    > nav {
      margin-top: 1rem;
      border-bottom: 1px solid $gray-400;

      .breadcrumb {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        margin-bottom: 0;
        border-bottom: none;
      }

      @include media-breakpoint-up(lg) {
        position: relative;
        z-index: 92;
        margin-bottom: -1px;

        .breadcrumb {
          background: $light;
        }
      }
    }

    .btn-toolbar:not(#assetContentToolbarTable) {
      margin-top: 0;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid $gray-400;
      background: none;

      @include media-breakpoint-up(lg) {
        position: sticky;
        z-index: 91;
        top: -1px;
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 1rem;
        padding-right: 1rem;
        background: $light;
      }
    }

    #assetTableContainer {
      .fixed-table-toolbar {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid $gray-400;

        @include media-breakpoint-up(lg) {
          position: sticky;
          z-index: 91;
          top: -1px;
          margin-left: -15px;
          margin-right: -15px;
          padding-left: 1rem;
          padding-right: 1rem;
          background: $light;
          margin-bottom: 0.5rem !important;
        }

        .bs-bars,
        .columns {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      .fixed-table-body {
        @include media-breakpoint-up(lg) {
          overflow: unset;

          thead {
            position: sticky;
            top: 54px;
          }
        }

        tbody {
          .tableFile {
            text-align: center;
            padding-bottom: 0.6rem;

            i {
              font-size: 1.8rem;
            }

            img {
              margin-bottom: 0.25rem;
            }
          }

          .tableFileName {
            word-break: break-word;
          }

          @include media-breakpoint-up(xl) {
            .tableFileDate,
            .tableFileTyp {
              white-space: nowrap;
            }
          }
        }

        .table-sm {
          tbody {
            .tableFile {
              padding-bottom: 0.3rem;

              i {
                font-size: 1.15rem;
                margin-top: 0.2rem;
              }

              img {
                height: 1.1rem;
                margin-bottom: 0.1rem;
              }
            }
          }
        }
      }
    }
  }

  .assetFolderIcon {
    color: $warning;

    &.virtualFolder {
      color: $primary;
    }
  }

  .assetFolderAction,
  .assetElementCheckbox,
  .assetElementPreview {
    &:not(.btn-inline) {
      box-shadow: 0 0 4px $black;
    }
  }

  .assetFolderAction,
  .assetElementPreview,
  .assetElementStatus {
    &.btn-sm {
      padding: 0.2rem;
      line-height: 0.6rem;
      font-size: 0.7rem;

      i,
      span {
        text-shadow: none;
      }
    }
  }

  .assetElementPreview {
    position: absolute;
  }

  .alert {
    &.uploadAlert {
      border: dashed;
    }

    .dropzone {
      // Werte mussen unset sein, da sonst BS-Layout nicht greift/überschrieben wird
      min-height: unset;
      border: unset;
      background: unset;
      padding: unset;
    }
  }

  .ribbon {
    .alert {
      margin-top: 0.25rem;
    }
  }

  .btnBarSticky {
    .ribbon {
      .alert {
        margin-bottom: 0;
      }
    }
  }

  .token-input-list-facebook {
    padding-left: 0.275rem;
    padding-right: 0.275rem;

    .token-input-token-facebook {
      margin-top: 0.3rem;
      font-size: 0.8rem;
    }

    &:not(.token-input-focused-facebook) {
      .token-input-input-token-facebook:only-of-type::before {
        content: 'Schlagworte';
        margin-left: 7px;
      }
    }
  }

  #treeContainer {
    &[style*="display: none"] ~ #mainContentContainer {
      .assetRow {
        .assetElement {
          @include media-breakpoint-up(lg) {
            width: grd12(calc(12/11));
          }

          @include media-breakpoint-up(xl) {
            width: grd12(calc(12/13));
          }

          // kein BS-Breakpoint vorhanden
          @media (min-width: 1500px) {
            width: grd12(calc(12/17));
          }
        }

        &.assetRowBig {
          .assetElement {
            @include media-breakpoint-up(lg) {
              width: grd12(calc(12/6));
            }

            @include media-breakpoint-up(xl) {
              width: grd12(calc(12/7));
            }

            // kein BS-Breakpoint vorhanden
            @media (min-width: 1500px) {
              width: grd12(calc(12/8));
            }
          }
        }
      }
    }
  }

  .assetRow {
    display: flex;
    flex-wrap: wrap;
    margin-left: -4px;
    margin-right: -4px;

    .assetElement {
      margin: 1px;
      width: grd9(calc(9/4));

      @include media-breakpoint-up(sm) {
        width: grd9(calc(9/6));
      }

      @include media-breakpoint-up(md) {
        width: grd9(calc(9/8));
      }

      @include media-breakpoint-up(lg) {
        width: grd9(calc(9/7));
      }

      @include media-breakpoint-up(xl) {
        width: grd9(calc(9/9));
      }

      // kein BS-Breakpoint vorhanden
      @media (min-width: 1500px) {
        width: grd9(calc(9/13));
      }

      .assetElementFileIcon {
        i {
          font-size: 3.15rem;
        }
      }

      .assetFolderLabel,
      .assetElementFileId,
      .assetElementFileLabel {
        font-size: 0.8rem;
        line-height: 1rem;
      }

      .assetElementInner {
        padding: 3px;
        border: 1px;
        margin-bottom: 0;
        position: relative;
        min-width: 30px;

        &:hover {
          text-decoration: none;

          .assetFolderIcon,
          .assetFolderLabel,
          .assetElementFileBox .assetElementFile,
          .assetElementFileBox .assetElementFileIcon,
          .assetElementFileBox .assetElementFileId,
          .assetElementFileLabel {
            transition: opacity 0.2s;
          }

          .assetFolderIcon,
          .assetElementFileBox .assetElementFile,
          .assetElementFileBox .assetElementFileIcon {
            opacity: 0.5;
          }

          .assetFolderLabel,
          .assetElementFileBox .assetElementFileId,
          .assetElementFileLabel {
            opacity: 0.8;
          }
        }

        .assetElementIconBox {
          position: relative;
          padding-top: 75%;

          .text-center {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
          }

          .position-relative {
            width: 50px !important;

            .assetElementCheckbox {
              position: absolute;
              left: -7px;
              z-index: 1;
              scale: 1.25;
            }

            .assetFolderIcon {
              font-size: 3.15rem;
            }
          }
        }

        .assetFolderAction {
          position: absolute;
          right: -7px;
          bottom: 0;
        }

        .assetElementPreview {
          right: 3px;
          bottom: 23px;
        }

        .assetFolderLabel {
          width: 100%;
          padding-top: 4px;
          text-align: center;
          hyphens: auto;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .assetElementFileBox {
          position: relative;
          overflow: hidden;
          @include border-radius($alert-border-radius);

          .assetElementFileIcon {
            padding-top: 100%; // dass es zu den AssetService-Vorschaubildern passt

            i {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .assetElementFile {
            width: 100%;
            margin: unset;
          }

          .assetElementStatus {
            text-shadow: 0 0 4px $black;
          }

          .assetElementCheckbox {
            position: absolute;
            top: 3px;
            left: 3px;
            scale: 1.25;
          }

          .assetElementStatus {
            position: absolute;
            top: 3px;
            right: 3px;
          }

          .assetElementFileId {
            background: $dlrgGreyTransparent;
            color: $white;
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 0.1rem 0.3rem;
          }
        }

        .assetElementFileId,
        .assetElementFileLabel {
          display: block;
          width: 100%;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
        }

        .assetElementFileLabel {
          padding-top: 4px;
          z-index: 1;
          position: relative;
        }
      }

      // Links (.assetElementFileLabel) nicht stylen
      .assetElementFileLabel {
        color: inherit;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &.assetRowBig {
      .assetElement {
        width: grd9(calc(9/3));

        @include media-breakpoint-up(sm) {
          width: grd9(calc(9/4));
        }

        @include media-breakpoint-up(md) {
          width: grd9(calc(9/5));
        }

        @include media-breakpoint-up(lg) {
          width: grd9(calc(9/5));
        }

        @include media-breakpoint-up(xl) {
          width: grd9(calc(9/6));
        }

        // kein BS-Breakpoint vorhanden
        @media (min-width: 1500px) {
          width: grd9(calc(9/8));
        }

        .assetElementIconBox {
          .position-relative {
            width: 70px !important;

            .assetFolderIcon {
              font-size: 4.3rem;
            }
          }
        }

        .assetElementFileIcon {
          i {
            font-size: 4.3rem;
          }
        }

        .assetFolderLabel,
        .assetElementFileId,
        .assetElementFileLabel {
          font-size: 0.9rem;
          line-height: 1.1rem;
        }
      }
    }
  }

  .rotateImageLeft {
    margin-top: -2.2px;
    margin-left: 2.5px;
  }

  .rotateImageRight {
    margin-top: -2.2px;
    margin-left: -2.5px;
  }

  .img-container {
    max-height: 60vH;
  }
}

.assetPickerFaIconBig {
  font-size: 5em;
  width: 100%;
}


// Asset-Picker
.pickerMainContentContainer {
  .fixCardToolbar {
    margin-left: -8px !important;
    margin-right: -8px !important;
    top: -8px !important;;
  }
}