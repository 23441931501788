@function grd12($flx-width, $flx-margin: 10px){
  @return calc(#{(calc($flx-width / 12) * 100%)} - #{$flx-margin});
}

#contentseite,
#startseite {
  .dropzone {
    display: flex;
    flex-wrap: wrap;

    .row,
    .dz-message {
      width: 100%;

      .dz-button {
        background: none !important;
      }
    }

    &.dz-started {
      .dz-message {
        display: none;
      }
    }

    .dz-message {
      text-align: center;
      margin: 2em 0;

      .dz-button {
        color: inherit;
        border: none;
        padding: 0;
      }
    }

    .dz-preview {
      position: relative;
      margin: 5px;
      width: grd12(calc(12/2));

      // sm/md gleicher Breakpoint, da hier Menü links wegfällt
      @include media-breakpoint-up(sm) {
        width: grd12(calc(12/4));
      }

      @include media-breakpoint-up(lg) {
        width: grd12(calc(12/6));
      }

      @include media-breakpoint-up(xl) {
        width: grd12(calc(12/7));
      }

      // kein BS-Breakpoint vorhanden
      @media (min-width: 1500px) {
        width: grd12(calc(12/9));
      }

      &.dz-success,
      &.dz-error {
        .beforeUpload {
          display: none;
        }

        .afterUpload {
          display: block;
        }
      }

      &.dz-success {
        .dz-success-mark {
          display: block;
        }

        .dz-progress {
          display: none;
        }
      }

      &.dz-error {
        .dz-error-mark {
          display: block;
        }

        .dz-progress {
          display: none;
        }
      }

      .dz-image {
        overflow: hidden;
        @include border-radius($alert-border-radius);

        img {
          width: 100%;
          margin: 0;
        }
      }

      .dz-progress {
        position: absolute;
        z-index: 1000;
        height: 10px;
        margin-top: -5px;
        left: 50%;
        top: 45px;
        transform: translate(-50%, -50%);
        width: 80%;
        pointer-events: none;
        background: $secondary;
        overflow: hidden;
        @include border-radius($alert-border-radius);

        .dz-upload {
          background: $success;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          transition: width .3s ease-in-out;
        }
      }

      .dz-details {
        padding: 4px 6px 0;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: -2.2rem;
        background: $dlrgGreyTransparent;
        color: $white;
        border-bottom-left-radius: $alert-border-radius;
        border-bottom-right-radius: $alert-border-radius;

      }

      .dz-size,
      .dz-filename,
      .dz-remove,
      .btn-sm {
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
        font-size: 0.8rem;
        line-height: 1rem;
      }

      .dz-size {
        order: 2;
      }

      .dz-filename {
        order: 1;
      }

      .dz-remove {
        overflow: hidden;
        width: 100%;
        display: block;

        &:hover {
          text-decoration: none;
        }
      }

      .btn-sm {
        padding: 0.1rem 0.3rem;
      }

      .dz-error-message {
        text-align: center;
        font-size: 0.8rem;
        line-height: 1rem;
        color: $warning;
      }

      .beforeUpload {
        display: block;
      }

      .afterUpload {
        display: none;
      }

      .dz-success-mark,
      .dz-error-mark {
        display: none;
        position: absolute;
        top: 0;
        right: 5px;

        svg {
          width: 16px;
          height: 16px;
          filter: drop-shadow(0 0 4px $black);
          border-radius: 8px;
          background: $white;

          path {
            fill-opacity: unset;
            stroke-opacity: unset;
          }
        }
      }

      .dz-success-mark {
        path {
          fill: $success;
          stroke: $success;
        }
      }

      .dz-error-mark {
        path {
          fill: $danger;
          stroke: $danger;
        }
      }
    }
  }
}