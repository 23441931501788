@function grd($flx-width, $flx-margin: 10px){
  @return calc(#{(calc($flx-width / 12) * 100%)} - #{$flx-margin});
}

.apps .apps_card .apps_card_inner,
.btn-hilfe {
  background: $gray-300;

  &:hover {
    background: $gray-400;
  }
}

.apps {
  display: flex;
  flex-wrap: wrap;

  .apps_row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1.5rem;

    .apps_row_header {
      width: 100%;

      p {
        margin-bottom: 0.2rem;
      }

      h4 {
        margin-top: 1rem;
        margin-bottom: 0;
      }
    }
  }

  .dlrg-alert-toolbar {
    .apps_row {
      margin-bottom: 0;

      .apps_row_header {
        h4 {
          margin-top: 0;
        }
      }
    }
  }

  .apps_card {
    width: grd(calc(12/4));
    max-width: 105px;
    margin: 5px;
    cursor: pointer;
    min-width: 70px;
    color: inherit;
    transition: all .2s;

    &.doc_card {
      width: grd(calc(12/2));

      .apps_abk {
        color: $white;
      }
    }

    @include media-breakpoint-up(sm) {
      width: grd(calc(12/6));

      &.doc_card {
        width: grd(calc(12/3));
      }
    }

    @include media-breakpoint-up(md) {
      width: grd(calc(12/7));

      &.doc_card {
        width: grd(calc(12/4));
      }
    }

    @include media-breakpoint-up(lg) {
      width: grd(calc(12/8));

      &.doc_card {
        width: grd(calc(12/6));
      }
    }

    @include media-breakpoint-up(xl) {
      width: grd(calc(12/10));

      &.doc_card {
        width: grd(calc(12/8));
      }
    }

    // kein BS-Breakpoint vorhanden
    @media (min-width: 1600px) {
      width: grd(calc(12/14));

      &.doc_card {
        width: grd(calc(12/12));
      }
    }

    &:hover {
      text-decoration: none;

      .apps_card_inner {
        .apps_card_favourit {
          display: inline-flex;

          &:hover {
            color: $warning;
          }
        }
      }
    }

    &.favourit:hover {
      .apps_card_inner {
        .apps_card_favourit {
          &:hover {
            color: inherit;
          }
        }
      }
    }

    &.favourit {
      .apps_card_inner {
        .apps_card_favourit {
          color: $warning;
        }
      }
    }

    .apps_card_inner {
      position: relative;
      padding-top: 100%;
      border-radius: 7px;

      .text-center {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
      }

      .apps_icon {
        font-size: 2rem;

        &.doc_icon {
          font-size: 5rem !important; // bei doc_icon immer 5rem
        }

        @include media-breakpoint-up(sm) {
          font-size: 2.25rem;
        }

        @include media-breakpoint-up(md) {
          font-size: 2.5rem;
        }

        @include media-breakpoint-up(lg) {
          font-size: 2.75rem;
        }

        @include media-breakpoint-up(xl) {
          font-size: 3rem;
        }

        //da kein BS-Breakpoint vorhanden
        @media (min-width: 1500px) {
          font-size: 3.5rem;

          &.doc_icon {
            font-size: 4rem;
          }
        }
      }

      .apps_abk {
        font-size: 0.55em;
      }

      .apps_card_help,
      .apps_card_favourit,
      .apps_card_new {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: $white;
        width: 20%;
        height: 20%;
        min-width: 20px;
        min-height: 20px;
        border-radius: 10px;
        font-size: 0.8rem;

        @include media-breakpoint-up(sm) {
          font-size: 0.85rem;
        }

        @include media-breakpoint-up(md) {
          font-size: 0.9rem;
        }

        @include media-breakpoint-up(lg) {
          font-size: 0.95rem;
        }

        @include media-breakpoint-up(xl) {
          font-size: 1rem;
        }
      }

      .apps_card_help {
        bottom: -5px;
        right: -5px;
        background: $dlrgColorBlue;
        font-size: 1rem;
      }

      .apps_card_favourit {
        display: none;
        top: -5px;
        left: -5px;
        background: $gray-400;
      }

      .apps_card_new {
        top: -5px;
        right: -5px;
        background: $dlrgColorRed;
      }
    }

    .apps_card_label {
      width: 100%;
      padding-top: 4px;
      text-align: center;
      hyphens: manual;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 0.7rem;
      line-height: 1rem;

      @include media-breakpoint-up(md) {
        font-size: 0.8rem;
        line-height: 1.1rem;
      }

      @include media-breakpoint-up(xl) {
        font-size: 0.9rem;
        line-height: 1.2rem;
      }
    }
  }

  .apps_noRight {
    cursor: context-menu;

    &,
    &:hover {
      .apps_card_inner {
        background: $gray-100;
      }
    }

    .apps_icon,
    .apps_card_label{
      color: $secondary !important; // dass icon auf jeden Fall grau
    }
  }
}

.c_meineServices {
  &.apps_icon,
  .apps_icon {
    color: $dlrgAppMeineServices;
  }
}

.c_allgemein {
  &.apps_icon,
  .apps_icon {
    color: $dlrgAppAllgemein;
  }
}

.c_ausbildung {
  &.apps_icon,
  .apps_icon {
    color: $dlrgAppAusbildung;
  }
}

.c_einsatz {
  &.apps_icon,
  .apps_icon {
    color: $dlrgAppEinsatz;
  }
}

.c_internet {
  &.apps_icon,
  .apps_icon {
    color: $dlrgAppInternet;
  }
}

.c_stammdaten {
  &.apps_icon,
  .apps_icon {
    color: $dlrgAppStammdaten;
  }
}

.c_links {
  &.apps_icon,
  .apps_icon {
    color: $dlrgAppLinks;
  }
}

.c_pruefungen {
  &.apps_icon,
  .apps_icon {
    color: $dlrgAppPruefungen;
  }
}

.c_tools {
  &.apps_icon,
  .apps_icon {
    color: $dlrgAppTools;
  }
}

.c_verwaltung {
  &.apps_icon,
  .apps_icon {
    color: $dlrgAppVerwaltung;
  }
}

.fa-red {
  color: $dlrgColorRed;
}