body {
  padding-top: 57px;
  background: $mainColorWhite;
}

a {
  color: $linkColor;

  &:hover {
    color: $linkColorHover;
    text-decoration: underline;
  }
}

img {
  margin: 0 0 -3px;
}

:required {
  &,
  & + .dropdown-toggle {
    border-left: $dlrgColorGreenHover solid 5px !important; // dass border von parsley nicht überschrieben wird
  }
}

form {
  input,
  select,
  textarea {
    &.parsley-success {
      &,
      & + .dropdown-toggle {
        color: $parsleySuccessColor !important;
        background-color: $parsleySuccessBg !important;
        border: 1px solid $parsleySuccessBorder;
      }
    }

    &.parsley-error {
      &,
      & + .dropdown-toggle {
        color: $parsleyErrorColor !important;
        background-color: $parsleyErrorBg !important;
        border: 1px solid $parsleyErrorBorder;
      }
    }
  }
}

.nav.sidebar ul,
ul.nav {
  padding-left: 15px !important;
}

[onclick]:not(:disabled) {
  cursor: pointer;
}