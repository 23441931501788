header {
  background: $dlrgColorRed;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 101;

  .navbar {
    border-bottom: solid 1px $dlrgColorRed;
    min-height: 57px;

    .navbar-brand {
      @include media-breakpoint-down(lg) {
        flex: 1 1 auto !important;
      }

      img {
        display: block;
        max-width: 229px;

        @include media-breakpoint-up(xl) {
          width: 229px;
        }

        &.seminarLogo {
          max-width: 506px;

          @include media-breakpoint-up(xl) {
            width: 506px;
          }
        }
      }
    }

    .navbar-toggler:not(.collapsed) {
      background: rgba(0, 0, 0, 0.2);
    }

    &.navbar-dark {
      .navbar-toggler,
      .navbar-nav .nav-link {
        color: $white;

        &:hover {
          opacity: .75;
        }
      }
    }
  }

  #gldPickerMenu .dropdown-menu {
    @include media-breakpoint-down(sm) {
      right: -139px;
    }
    
    max-width: calc(100vw - 1.75rem);
    
    .dropdown-item {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
