.form-control,
.form-control-sm {
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
}

.form-group {
  // Anpassung mehrfach DropDown-Input
  .bootstrap-select {
    select.selectpicker:not(.parsley-success):not(.parsley-error) + .btn {
      color: inherit !important;
    }

    .btn {
      background: none;
      border: $input-border-width solid $input-border-color;
      margin: 0 !important; // absolute Absicht
    }

    .dropdown-menu {
      margin-top: 0;
      max-width: calc(100vW - 60px);
    }
  }
}

.form-group {
  input,
  select,
  .form-control,
  .input-group-prepend .input-group-text,
  .input-group-append .input-group-text,
  .input-group {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .form-control {
    min-width: 150px;
  }

  .input-group {
    input,
    select,
    .form-control,
    .input-group,
    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.cke {
  border-radius: 0.25rem;
  overflow: hidden;
}

// Anpassung nicht-BS-Inputs
.form-group .input-group > input[type="text"]:not([class]),
.form-group .input-group > input[type="text"][class=""],
.form-group .input-group > select:not([class]),
.form-group .input-group > select[class=""],
ul.token-input-list-facebook {
  background: $input-bg !important; // dass Inline-Werte überschrieben werden
  color: $input-color;
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid $input-border-color;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.input-group {
  .form-group .input-group > select[class=""],
  ul.token-input-list-facebook {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

ul.token-input-list-facebook {
  padding-top: 1px;
  padding-bottom: 1px;

  input[type="text"]:not([class]),
  input[type="text"][class=""] {
    background: $input-bg;
    color: $input-color;
  }
}

.dlrg-alert-toolbar,
.btn-toolbar {
  .form-group:last-of-type {
    margin-bottom: 0;
  }
}

.country-picker-flag {
  margin-right: 5px;
  width: 35px;
  display: inline-block;
  text-align: right;

  img {
    height: 1rem;
    vertical-align: baseline;
  }
}