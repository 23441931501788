// quick fixes - pls clean asap

// da Multisort eine CSS-Klasse fehlt (Bug in BS-Table)
.fixed-table-toolbar .multi-sort.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

// Icons in Action-Spalte sollen alle die gleiche Größe haben.
// daher bei Icons das padding verringern und feste Größe setzen
.dlrg-table {
  td:last-of-type {
    .btn:not(.assetFolderAction) {
      margin: 0.1rem;
      min-width: 34px;
      padding-left: 0;
      padding-right: 0;
    }

    .btn-group {
      .btn {
        margin: 0;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }
  }
}