.footer {
  bottom: 0;
  padding: 1rem 0;
  margin-top: 2rem;
  width: 100%;
  font-size: 85%;
  border-top: 1px solid $gray-400;
  color: $secondary;
  clear: both;

  a {
    font-weight: bold;
    color: inherit;

    &:hover {
      color: $headerColorGrey;
    }
  }

  li {
    display: inline-block;
  }
}