.alert {
  .alert-link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .close {
    color: unset;
  }

  &.dlrgFormAlert {
    padding-top: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  &.withErrorCode {
    min-height: 63px;
    padding-right: 6.25rem;

    .errorCode {
      position: absolute;
      right: 5px;
      top: 0;
      padding: 0.8rem;
    }
  }

  .form-group:last-of-type {
    margin-bottom: 0;
  }
}

#dlrgToastContainer {
  position: fixed;
  z-index: 1055;
  width: 100%;

  #dlrgToastWrapper {
    position: absolute;
    right: 0;
    margin: 7.5px;
    margin-top: 8.5px;

    .toast {
      min-width: 150px;

      &:not(:last-child) {
        margin-bottom: 7.5px;
      }

      a {
        color: black;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      .toast-header {
        &:last-of-type {
          border-bottom: 0;
          border-radius: $toast-border-radius;
        }

        &.bg-success,
        &.bg-danger,
        &.bg-info {
          color: $white;
        }

        &.bg-warning {
          color: $black;
        }

        .close {
          color: inherit;
        }
      }
    }
  }
}