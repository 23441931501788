table.table-hover tbody tr:hover {
  background-color: $table-hover-bg;
}

#contentseite,
#startseite {
  table {
    hyphens: auto;
    overflow-wrap: break-word;
    color: inherit;
    background-color: unset;

    &:not(.fc-scrollgrid) {
      // statt "border-collapse: collapse"
      // sonst sieht man border nicht, wenn thead sticky ist
      border-collapse: separate;
      border-spacing: 0;

      th,
      td {
        padding-right: 7.5px;

        &:not(:first-of-type) {
          padding-left: 7.5px;
        }
      }
    }

    thead {
      th {
        border-bottom-width: 1px;

        .filter-control {
          margin-bottom: 0.5rem;
        }

        &:first-of-type {
          .filter-control {
            padding-left: 10px;
          }
        }
      }

      // Wenn mehrere th in thead soll außer der ersten keine border-top haben
      tr:not(:first-of-type) {
        th {
          border-top: unset;
        }
      }

      // Wenn mehrere th in thead soll außer der letzten keine border-bottom haben
      tr:not(:last-of-type) {
        th {
          border-bottom: unset;
        }
      }
    }

    tbody {
      // erste Zeile braucht kein border-top
      tr:first-of-type {
        th,
        td {
          border-top: none;
        }
      }

      tr {
        &:last-of-type {
          td {
            border-bottom: 1px solid $table-border-color;
          }
        }

        td.px-1:first-of-type {
          padding-left: 0.75rem !important;
        }

        td.px-1:last-of-type {
          padding-right: 0.75rem !important;
        }
      }
    }

    .form-control {
      padding-left: 5px;
      padding-right: 5px;
    }

    .tableBlockBorderTop {
      td {
        border-top: 2px solid $black;
      }
    }
  }


  .bootstrap-table {
    .fixed-table-toolbar {
      margin-top: unset;
    }

    .fixed-table-container {
      .table {
        thead {
          th {
            .th-inner {
              &.sortable {
                padding-right: 19px !important; //weil BS schon important
              }
            }

            &:not(:first-of-type) {
              .th-inner {
                padding-left: 0;
                white-space: unset;
                min-width: 50px;
              }
            }
          }
        }

        tbody {
          tr {
            &.selected {
              opacity: 0.7;
            }

            .card-view {
              hyphens: auto;
            }
          }
        }
      }

      .fixed-table-body {
        .fixed-table-loading {
          background: $gray-400;
        }
      }

      &.has-card-view {
        .text-center,
        .text-right {
          text-align: left !important;
        }
      }
    }
  }

  .table-header-rotated {
    .rotateHeader {
      min-width: 50px;

      .th-inner.sortable {
        &.both,
        &.asc,
        &.desc {
          background-position: calc(100% + 5px) calc(100% - 14px) !important;
        }
      }

      &.hasFilter {
        .th-inner.sortable {
          &.both,
          &.asc,
          &.desc {
            background-position: calc(100% + 5px) calc(100% - 47px) !important;
          }
        }
      }
    }

    .th-inner {
      overflow: inherit !important;
      white-space: nowrap !important;
      min-width: unset !important;

      > span {
        transform: translate(-2px, 0) rotate(-45deg);
        width: 10px;
        display: block;
      }
    }
  }

  .dlrg-border-left {
    border-left: 1px solid $gray-400;
  }

  //während CardAcc auf-/zuklappt kein position/top
  .collapsing {
    thead,
    .fixed-table-toolbar {
      top: unset !important;
    }
  }

  .dlrg-table {
    thead {
      tr {
        height: 100%;

        th.hasFilter {
          position: relative;

          .th-inner {
            padding-bottom: 45px;
          }

          .fht-cell {
            position: absolute;
            bottom: 0;
            width: calc(100% - 15px);
          }

          .th-inner.sortable {
            background-position: right top 15px;
          }

          .form-control,
          .form-control-sm {
            height: 30px;
            padding: 2px;
          }
        }
      }
    }

    tfoot {
      background: $gray-300;

      tr:first-of-type {
        th {
          border-top-color: $dlrgColorThead;
        }
      }
    }

    .actionsCount_1,
    .actionsCount_2,
    .actionsCount_3,
    .actionsCount_4,
    .actionsCount_5,
    .actionsCount_6,
    .actionsCount_7,
    .actionsCount_8,
    .actionsCount_9,
    .actionsCount_10 {
      vertical-align: top !important; // Inline-Style überschreiben
    }

    .actionsCount_4,
    .actionsCount_5,
    .actionsCount_6,
    .actionsCount_7,
    .actionsCount_8,
    .actionsCount_9,
    .actionsCount_10 {
      min-width: 135px;
    }

    .actionsCount_1 {
      width: 45px;
    }

    .actionsCount_2 {
      width: 90px;
    }

    .actionsCount_3 {
      width: 135px;
    }

    .actionsCount_4 {
      max-width: 180px;
    }

    .actionsCount_5 {
      max-width: 225px;
    }

    .actionsCount_6 {
      max-width: 270px;
    }

    .actionsCount_7 {
      max-width: 315px;
    }

    .actionsCount_8 {
      max-width: 360px;
    }

    .actionsCount_9 {
      max-width: 405px;
    }

    .actionsCount_10 {
      max-width: 450px;
    }
  }

  // rotierte Header werden sonst falsch dargestellt
  .dropdown-menu > .dropdown-item > span > div {
    display: none;
  }

  .dlrg-table thead,
  .fc-media-screen .fc-col-header-cell {
    &,
    & a {
      background: $dlrgColorThead;
      color: $white;
    }
  }

  .fc-media-screen .fc-col-header-cell .fc-header-toolbar {
    flex-wrap: wrap;
  }
}