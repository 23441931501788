.sidebar {
  position: fixed;
  top: 59px;
  bottom: 0;
  left: 0;
  padding: 0;
  z-index: 100;
  border-right: 1px solid $gray-400;
  display: block !important;

  @media (min-width: 1200px) {
    max-width: 300px;
  }

  .nav-inner {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 59px);
    padding-top: 10px;
    /*
    background-image: url('/global/layout/isc/img/adler_light.svg');
    background-position-y: calc(100% + 5px);
    background-repeat: no-repeat;
    background-size: 84%;
    background-position-x: 248%;
    */

    > .fa-ul {
      margin-left: 1.85rem;
    }

    .nav-link {
      color: $linkColor;

      &:focus, &:hover, &:active {
        color: $linkColorHover !important;
      }

      &:hover {
        text-decoration: underline;
      }

      &.active {
        background: none !important;
        font-weight: bold !important;
      }
    }

    li {
      color: $linkColor !important;
    }

    .nav {
      margin-bottom: 20px;
    }

    .nav-item {
      width: 100%;
      list-style: none;
      white-space: nowrap;

      & + .nav-item {
        margin-left: 0;
      }

      .fa {
        position: relative;
        bottom: 2px;
        font-size: 9px;
      }

      .fa-circle {
        bottom: 4px;
        font-size: 6px;
      }
    }

    .nav-link {
      border-radius: 0;
      display: inline-block;
      padding: .2rem .5rem;
    }
  }

  #menu-toggle {
    position: absolute;
    bottom: 10px;
    right: 20px;
  }

  &.collapsed {
    width: 50px;

    .nav-inner {
      display: none;
    }

    #menu-toggle {
      right: 7px;

      &:before {
        content:"\f101";
      }
    }

    + main {
      flex: 1;
      max-width: calc(100% - 50px) !important;
    }

    @media (max-width: 767px) {
      width: 0;

      #menu-toggle {
        right: -45px;
      }

      + main {
        flex: 1;
        max-width: 100% !important;
      }
    }
  }
}