.card {
  .tab-content,
  .card-body {
    .list-group {
      .list-group-item {
        padding-left: 0;
        padding-right: 0;
        border-color: $borderColorGrey;

        &:first-of-type {
          padding-top: 0;
        }

        &:last-of-type {
          padding-bottom: 0;
        }

        h4:not(.card-header):not(:first-child) {
          margin-top: .5rem;
        }
      }
    }

    // direkter Card-Footer in CardTab soll bis zum Rand gehen
    .tab-pane {
      &,
      > form {
        > .card-footer {
          margin: -1.25rem;
          margin-top: 0;
        }
      }
    }
  }

  .card-header,
  .card-footer {
    &:not([class*="alert-"]) {
      background: $headerColorLightGrey;
    }
  }

  .card-header {
    &.cardAccIcon {
      cursor: pointer;
      border-bottom: unset;

      &::before {
        font-family: $fontawesome;
        content: "\f068";
        float: right;
        color: $dlrgColorBlue;
        font-weight: normal;
        font-size: 1.5rem;
        margin-right: 10px;
      }

      &.collapsed::before {
        content: "\f067";
      }

      &.collapsed {
        @include border-radius($card-border-radius);
      }

      + .collapsing,
      + .collapse.show {
        .card-body {
          border-top: 1px solid $borderColorGrey;
        }
      }
    }

    .nav-tabs {
      padding-left: unset !important;

      .dropdown-menu {
        // notwendig, dass DropDown auf zu kleinen Bildschirmen (bzgl. Höhe) nicht verspringt
        transform: unset !important; // dass inline CSS von JS überschrieben wird
        top: 42px !important; // dass inline CSS von JS überschrieben wird
        left: unset !important; // dass inline CSS von JS überschrieben wird
        right: 0 !important ; // dass inline CSS von JS überschrieben wird
        max-width: calc(100vW - 52px);
        max-height: 50vH;
        overflow-y: auto;

        .dropdown-item {
          white-space: normal;
          padding: 0.5rem 1.5rem;
        }
      }

      .nav-link.alert-danger {
        background-color: $parsleyErrorBg !important;

        &:hover,
        &:active,
        &:focus,
        &.active {
          color: $parsleyErrorColor !important;
        }
      }
    }

    .dlrg-spaltenelement {
      *[class*="col"] {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &:last-child {
      border-radius: $card-inner-border-radius;
      border: none;
    }
  }
}

.card,
.modal {
  .tab-content,
  & {
    &,
    .card-body,
    .list-group,
    .list-group-item,
    form {
      & > .card.cardAcc {
        background: none;
        border: none;

        .card-header,
        .card-body,
        .card-footer {
          padding-left: 0;
          padding-right: 0;
        }

        .card-header {
          background: none;
          border-bottom: 1px solid $card-border-color;
          border-radius: 0;
        }

        .card-body {
          border-top: none !important;
          border-bottom: 1px solid $card-border-color;
          padding-bottom: 0;
        }

        .card-footer {
          border-top: none !important;
          background: none;
          border-bottom: 1px solid $card-border-color;
        }
      }
    }
  }
}